import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TicketcontrolService {
  baseUrl: any;
  countUrl: any;
  apiKey: any;
  httpOptions: any;

  constructor(private http: HttpClient, private router: Router) {
  	// this.baseUrl = 'https://www.otayo.com/tickets/tc-api/';
  	this.baseUrl = 'https://calypso.abzgroup.info/otayocontroltest/checkin.php?api=';
    this.countUrl = 'https://calypso.abzgroup.info/otayocontroltest/getcount.php?api=';
  	this.apiKey = ''; //71FD1124, D226A1E1
  	this.httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'})
    };
  	// 'https://www.otayo.com/tickets/tc-api/'+this.apiKey+'/check_in/'+code+'/?new=' + Math.random()'
  }

  checkticket(qrcode, apikey) {
  	this.apiKey = apikey;
    var timestamp = new Date().getUTCMilliseconds();
  	return this.http.get(this.baseUrl+apikey+'&code='+qrcode+'&new=' + Math.random() + timestamp, this.httpOptions)
      .toPromise();
  }

  async getcount(apikey) {
  	this.apiKey = apikey;
  	return await this.http.get(this.countUrl+apikey+'&new=' + Math.random(), this.httpOptions)
      .toPromise();
  }

  insertLog(apikey, scannerdata) {
    this.apiKey = apikey;

    var params = {
      apikey: apikey,
      action: 'insert_log',
      ...scannerdata
    };
    return this.http.post('https://otayo.com/producersapi/permanentcontrollogs.php?new=' + Math.random(), params, this.httpOptions)
      .toPromise();
  }

  getLogs(apikey) {
    this.apiKey = apikey;

    var params = {
      apikey: apikey,
      action: 'get_logs',
    };
    return this.http.post('https://otayo.com/producersapi/permanentcontrollogs.php?new=' + Math.random(), params, this.httpOptions)
      .toPromise();
  }
}
