import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { EventsapiService } from '../eventsapi.service';
import { AuthenticationService } from '../authentication.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css']
})
export class EventComponent implements OnInit {

  carouselUploadedFiles: Array<File>;
  gridUploadedFiles: Array<File>;

  eventname: any;
  briefdescription: any;
  eventdate: any;
  eventenddate: any;
  eventtime: any;
  eventendtime: any;
  eventvenue: any;
  eventlocation: any;
  eventprice: any;
  eventtype: any;
  eventvideos: any;
  eventabout: any;
  eventaboutartist: any;
  eventadditionalinfo: any;
  eventmetatitle: any;
  eventmetadescription: any;
  eventgridimage: any;
  eventcarouselimage: any;
  apiLink: any;
  uploadedImages: any;
  error: any;
  success: any;
  producerName: any;
  producerDisplayName: any;
  producerEmail: any;

  step1: any;
  step2: any;
  step3: any;
  step4: any;
  submitBtn: any;
  agreeCheck: any;

  constructor(private http: HttpClient, private eventsapi: EventsapiService, private authapi: AuthenticationService, private router: Router) {
    this.emptyValues();
    // this.apiLink = 'http://otayo.com:2052';
    this.apiLink = "https://xml.otayo.com";
    this.producerName = this.authapi.getProd();
    this.producerDisplayName = this.authapi.getUserDisplayName();
    console.log(this.authapi.getRole());
  }

  emptyValues() {
    this.eventname = '';
    this.briefdescription = '';
    this.eventdate = '';
    this.eventenddate = '';
    this.eventtime = '';
    this.eventendtime = '';
    this.eventvenue = '';
    this.eventlocation = '';
    this.eventprice = 0;
    this.eventtype = '';
    this.eventvideos = '';
    this.eventabout = '';
    this.eventaboutartist = '';
    this.eventadditionalinfo = '';
    this.eventmetatitle = '';
    this.eventmetadescription = '';
    this.eventgridimage = '';
    this.eventcarouselimage = '';

    this.uploadedImages = '';
    this.error = false;
    this.success = false;

    this.gridUploadedFiles = [];
    this.carouselUploadedFiles = [];

    this.producerName = '';
    this.producerDisplayName = '';
    this.producerEmail = '';

    this.step1 = true;
    this.step2 = false;
    this.step3 = false;
    this.step4 = false;
    this.submitBtn = false;
    this.agreeCheck = false;
  }

  ngOnInit() {
    this.producerEmail = this.authapi.getUserEmail();
    if (this.authapi.getToken() == null) {
      this.router.navigateByUrl('/login');
    }
    setTimeout(function () {
      document.getElementById('datepicker_input').setAttribute('readonly', 'readonly');
      document.getElementById('datepicker2_input').setAttribute('readonly', 'readonly');
      document.getElementById('timepicker_input').setAttribute('readonly', 'readonly');
      document.getElementById('timepicker2_input').setAttribute('readonly', 'readonly');
    }, 2000);
  }

  carouselFileChange(element) {
    this.carouselUploadedFiles = element.target.files;
    // console.log(this.carouselUploadedFiles);
    this.goToStep4();
  }

  gridFileChange(element) {
    this.gridUploadedFiles = element.target.files;
    // console.log(this.gridUploadedFiles);
    this.goToStep4();
  }

  async uploadCarousel() {
    let formData = new FormData();
    for (var i = 0; i < this.carouselUploadedFiles.length; i++) {
      formData.append("uploads[]", this.carouselUploadedFiles[i], this.carouselUploadedFiles[i].name);
    }
    await this.eventsapi.uploadfiles(formData)
      .then((response) => {
        this.uploadedImages = response;
        this.uploadedImages.forEach((image) => {
          this.eventcarouselimage = this.eventcarouselimage + this.apiLink + '/' + image.path + ',';
        });
        this.eventcarouselimage = this.eventcarouselimage.substring(0, this.eventcarouselimage.length - 1);
        console.log('response received is ', response);
        console.log(this.eventcarouselimage);
      })
  }

  async uploadGrid() {
    let formData = new FormData();
    for (var i = 0; i < this.gridUploadedFiles.length; i++) {
      formData.append("uploads[]", this.gridUploadedFiles[i], this.gridUploadedFiles[i].name);
    }
    await this.eventsapi.uploadfiles(formData)
      .then((response) => {
        this.eventgridimage = this.apiLink + '/' + response[0].path;
        console.log('response received is ', response);
        console.log(this.eventgridimage);
      })
  }

  async submitEventData() {
    if (this.eventprice == '') {
      this.eventprice = 0;
    }
    let data = {
      eventname: this.eventname,
      briefdescription: this.briefdescription,
      eventdate: this.eventdate,
      eventenddate: this.eventenddate,
      eventtime: this.eventtime,
      eventendtime: this.eventendtime,
      eventvenue: this.eventvenue,
      eventlocation: this.eventlocation,
      eventprice: this.eventprice,
      eventtype: this.eventtype,
      eventvideos: this.eventvideos.replace(/https:\/\/www.youtube.com\/watch?v=/g, ''),
      eventabout: this.eventabout,
      eventaboutartist: this.eventaboutartist,
      eventadditionalinfo: this.eventadditionalinfo,
      eventmetatitle: this.eventmetatitle,
      eventmetadescription: this.eventmetadescription,
      eventgridimage: this.eventgridimage,
      eventcarouselimage: this.eventcarouselimage,
      producerName: this.producerName,
      producerDisplayName: this.producerDisplayName,
      producerEmail: this.producerEmail,
      status: 'pending'
    };
    await this.eventsapi.submitData(data).then((response) => {
      console.log(response);
      if (response.hasOwnProperty('eventname') && response['eventname']) {
        this.emptyValues();
        this.eventsapi.notify(data).then((response) => {
          console.log('notified');
        });
        Swal.fire({
          title: 'Event Submitted',
          text: 'Thank you for submitting you event. Our team will review the data and will contact you soon. Once approved it will show in your dashboard',
          type: 'success',
          showCancelButton: true,
          confirmButtonText: 'Go to Dashboard',
          cancelButtonText: 'Submit another Event'
        }).then((result) => {
          if (result.value) {
            this.router.navigateByUrl('/submittedevents');
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            window.scroll(0, 0);
          }
        })
      } else {
        Swal.fire('Oops...', 'Something went wrong! Please check your data and try again', 'error');
      }
    });
  }

  async submitform() {
    this.eventmetatitle = this.eventname;
    this.eventmetadescription = this.briefdescription;
    let eventdate1 = moment(this.eventdate).format('YYYY-MM-DD');
    this.eventdate = eventdate1;
    let eventenddate1 = moment(this.eventenddate).format('YYYY-MM-DD');
    this.eventenddate = eventenddate1;
    let eventtime1 = moment(this.eventtime).format('hh:mm A');
    this.eventtime = eventtime1;
    let eventendtime1 = moment(this.eventendtime).format('hh:mm A');
    this.eventendtime = eventendtime1;

    if (this.eventname != '' && this.briefdescription != '' && this.eventdate != null && this.eventtime != null && this.eventvenue != '' && this.eventlocation != '' && this.eventtype != '' && this.eventabout != '' && this.eventmetatitle != '' && this.eventmetadescription != '' && this.carouselUploadedFiles.length > 0 && this.carouselUploadedFiles.length > 0) {
      await this.uploadCarousel();
      await this.uploadGrid();
      await this.submitEventData();
    } else {
      Swal.fire('Oops...', 'All the feilds marked as * are necessary please fill all the feilds and submit again.', 'error');
    }
  }

  goToStep2() {
    // console.log(this.eventvideos);
    if (this.eventname != '' && this.briefdescription != '' && this.eventdate != null && this.eventtime != null && this.eventvenue != '' && this.eventlocation != '' && this.eventtype != '') {
      this.step2 = true;
      this.step3 = false;
      this.step4 = false;
      this.scrollTo('step2');
    }
    else if (this.eventdate == null || this.eventtime == null) {
      Swal.fire('Oops...', 'Please enter Event Starting Date and Event Starting Time.', 'error');
    }
  }

  goToStep3() {
    if (this.eventabout != '') {
      this.eventmetatitle = this.eventname;
      this.eventmetadescription = this.briefdescription;
      this.step3 = true;
      this.step4 = false;
      this.scrollTo('step3');
    } else {
      // Swal.fire('Oops...', 'All the feilds marked as * are necessary please fill all the feilds and submit again.', 'error');
    }
  }

  goToStep4() {
    this.eventmetatitle = this.eventname;
    this.eventmetadescription = this.briefdescription;
    if (this.eventname != '' && this.briefdescription != '' && this.eventdate != null && this.eventtime != null && this.eventvenue != '' && this.eventlocation != '' && this.eventtype != '' && this.eventabout != '' && this.eventmetatitle != '' && this.eventmetadescription != '' && this.carouselUploadedFiles.length > 0 && this.carouselUploadedFiles.length > 0 && this.agreeCheck) {
      this.submitBtn = true;
    }
    this.step4 = true;
    this.scrollTo('step4');
  }

  checkChange() {
    this.agreeCheck = !this.agreeCheck;
    if (this.eventname != '' && this.briefdescription != '' && this.eventdate != null && this.eventtime != null && this.eventvenue != '' && this.eventlocation != '' && this.eventtype != '' && this.eventabout != '' && this.eventmetatitle != '' && this.eventmetadescription != '' && this.carouselUploadedFiles.length > 0 && this.carouselUploadedFiles.length > 0 && this.agreeCheck) {
      this.submitBtn = true;
    } else {
      this.submitBtn = false;
    }
    console.log(this.agreeCheck);
  }

  scrollTo(id) {
    document.getElementById(id).scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  openDatePicker() {
    console.log("Clicked on date");
    let element: HTMLElement = document.querySelector("#datepicker > span > span.e-input-group-icon.e-date-icon.e-icons") as HTMLElement;
    element.click();
  }

  openTimePicker() {
    console.log("Clicked on time");
    let element: HTMLElement = document.querySelector("#timepicker > span > span.e-input-group-icon.e-time-icon.e-icons") as HTMLElement;
    element.click();
  }

  registerEvents() {
    let dateelement: HTMLElement = document.querySelector("#datepicker_input") as HTMLElement;
    dateelement.onclick = function () {
      let element: HTMLElement = document.querySelector("#datepicker > span > span.e-input-group-icon.e-date-icon.e-icons") as HTMLElement;
      element.click();
    };

    let timeelement: HTMLElement = document.querySelector("#timepicker_input") as HTMLElement;
    timeelement.onclick = function () {
      let element: HTMLElement = document.querySelector("#timepicker > span > span.e-input-group-icon.e-time-icon.e-icons") as HTMLElement;
      element.click();
    };
  }

}
