import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventsapiService {

  apiLink: any;

  constructor(private http: HttpClient) {
    // this.apiLink = "http://otayo.com:2052";
    this.apiLink = "https://xml.otayo.com";
  }

  async uploadfiles(formData) {
    return await this.http.post(this.apiLink + '/api/upload', formData).toPromise();
  }

  async submitData(data) {
    return await this.http.post(this.apiLink + '/events', data).toPromise();
  }

  async getAllEvents() {
    return await this.http.get(this.apiLink + '/events').toPromise();
  }

  async getAllEventsPerProducer(producerUserName) {
    return await this.http.get(this.apiLink + '/events/producer/' + producerUserName).toPromise();
  }

  async getEvent(id) {
    return await this.http.get(this.apiLink + '/events/' + id).toPromise();
  }

  async updateStatus(id, data) {
    return await this.http.put(this.apiLink + '/events/' + id, data).toPromise();
  }

  async pushToWebsite(formData) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
    return await this.http.post('https://otayo.com/scripts/bin/insertproduct.php', JSON.stringify(formData), httpOptions).toPromise();
  }

  async notify(formData) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
    return await this.http.post('https://otayo.com/scripts/bin/neweventsubmitnotify.php', JSON.stringify(formData), httpOptions).toPromise();
  }

  async notifyProducer(formData) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
    return await this.http.post('https://otayo.com/scripts/bin/neweventsubmitnotifyproducer.php', JSON.stringify(formData), httpOptions).toPromise();
  }
}
