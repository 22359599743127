import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {

  constructor(private http: HttpClient, private router: Router) {

  }

  public getevents(prod): any {
    return this.http.get('https://otayo.com/producersapi/geteventsperprod.php?tok=ruer75874lkrlkjejdhrw3463434&prod=' + prod+"&rand="+Math.random());
  }

  public getreport(id: any): any {
    return this.http.get('https://otayo.com/producersapi/getreport.php?tok=ruer75874lkrlkjejdhrw3463434&id=' + id + "&rand=" + Math.random());
  }
}
