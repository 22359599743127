import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { ReportComponent } from './report/report.component';
import { EventComponent } from './event/event.component';
import { SubmittedeventsComponent } from './submittedevents/submittedevents.component';
import { VieweventComponent } from './viewevent/viewevent.component';
import { CsvComponent } from './csv/csv.component';
import { TicketcontrolComponent } from './ticketcontrol/ticketcontrol.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'report/:id', component: ReportComponent },
  { path: 'addevent', component: EventComponent },
  { path: 'submittedevents', component: SubmittedeventsComponent },
  { path: 'viewevent/:id', component: VieweventComponent },
  { path: 'csv', component: CsvComponent },
  { path: 'ticketcontrol', component: TicketcontrolComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
