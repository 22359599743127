import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { interval } from 'rxjs';
import { Howl, Howler } from 'howler';
import { ConnectionService } from 'ng-connection-service';
import { AuthenticationService } from '../authentication.service';
import { TicketcontrolService } from '../ticketcontrol.service';

@Component({
  selector: 'app-ticketcontrol',
  templateUrl: './ticketcontrol.component.html',
  styleUrls: ['./ticketcontrol.component.css']
})
export class TicketcontrolComponent implements OnInit {

  @ViewChild('scannerinputl') scannerinputl:ElementRef;
  @ViewChild('scannerinput2') scannerinput2:ElementRef;
  @ViewChild('scannerinput3') scannerinput3:ElementRef;
  @ViewChild('scannerinput4') scannerinput4:ElementRef;

  apikey: any;
  entrylogs: any;
  entrylogsSummary: any;
  totalAttendies: any;
  totalCheckedIn: any;
  currEvent: any;
  searchlog: any;
  searchlogResults: any;
  pendingbreakdown: any;
  checkedbreakdown: any;
  totalbreakdown: any;
  allLogs: any;

  scanner1: any;
  scanner2: any;
  scanner3: any;
  scanner4: any;

  scanner1status: any;
  scanner1statusword: any;
  scanner1buyername: any;
  scanner1tickettype: any;
  scanner1data: any;

  scanner2status: any;
  scanner2statusword: any;
  scanner2buyername: any;
  scanner2tickettype: any;
  scanner2data: any;

  scanner3status: any;
  scanner3statusword: any;
  scanner3buyername: any;
  scanner3tickettype: any;
  scanner3data: any;

  scanner4status: any;
  scanner4statusword: any;
  scanner4buyername: any;
  scanner4tickettype: any;
  scanner4data: any;

  sound: any;
  isConnected: any;
  internetStatus: any;
  localData: any;

  constructor(private auth: AuthenticationService, private router: Router, private controlService: TicketcontrolService, private connectionService: ConnectionService) {
    this.apikey = '';
    this.totalAttendies = 0;
    this.totalCheckedIn = 0;
    this.currEvent = 'No API KEY ADDED';
    this.searchlog = '';
    this.searchlogResults = [];
    this.pendingbreakdown = [];
    this.checkedbreakdown = [];
    this.totalbreakdown = [];
    this.allLogs = [];

  	this.scanner1 = '';
  	this.scanner2 = '';
  	this.scanner3 = '';
  	this.scanner4 = '';

  	this.scanner1status = '';
  	this.scanner1statusword = '';
  	this.scanner1buyername = '';
  	this.scanner1tickettype = '';
  	this.scanner1data = [];

  	this.scanner2status = '';
  	this.scanner2statusword = '';
  	this.scanner2buyername = '';
  	this.scanner2tickettype = '';
  	this.scanner2data = [];

  	this.scanner3status = '';
  	this.scanner3statusword = '';
  	this.scanner3buyername = '';
  	this.scanner3tickettype = '';
  	this.scanner3data = [];

  	this.scanner4status = '';
  	this.scanner4statusword = '';
  	this.scanner4buyername = '';
  	this.scanner4tickettype = '';
    this.scanner4data = [];

    this.entrylogs = [];
    this.entrylogsSummary = [];

    this.sound = new Howl({
      src: ['assets/sound.mp3']
    });

    this.internetStatus = "ONLINE";
    this.isConnected = true;
    this.localData = [];
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.internetStatus = "ONLINE";
        // console.log(this.localData);
        this.localData.forEach(entry => {
          this.controlService.checkticket(entry, this.apikey).then(data => {
            // console.log(data);
          }).catch(error => {
            console.log(error);
          });
        });
      }
      else {
        this.internetStatus = "OFFLINE";
      }
    })

    interval(1000 * 10).subscribe(x => {
      this.updateCount();
    });
  }

  ngOnInit() {
  	let userRole = this.auth.getRole();
    if(userRole != 'administrator') {
      this.router.navigateByUrl('/profile');
    } else {
    	console.log(`I am Administrator`);
    }
    this.switchinputs();
  }

  updateLocalData(scannedCode) {
    if(!this.isConnected) {
      this.localData.push(scannedCode);
    }
  }

  checkTicketLocal(code: any) {
    console.log(this.allLogs[code]);
    if (typeof this.allLogs[code] != 'undefined') {
      if(parseInt(this.allLogs[code].count)<1) {
        return true
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getDataLocal(code: any) {
    var status = false;
    var statusword = 'Not Valid';
    var data = {
      buyername: '',
      code: code,
      event: '',
      status: status,
      statusword: statusword,
      tsupdated: "",
      type: '',
    }

    if (typeof this.allLogs[code]!= 'undefined') {
      if (parseInt(this.allLogs[code].count)<1) {
        this.allLogs[code].count = 1;
        status = true;
        statusword = 'Valid';
      } else {
        status = false;
        statusword = 'Used';
      }

      data = {
        buyername: this.allLogs[code].name,
        code: code,
        event: this.allLogs[code].event,
        status: status,
        statusword: statusword,
        tsupdated: "NA",
        type: this.allLogs[code].type,
      }
    } else {
      data = {
        buyername: '',
        code: code,
        event: '',
        status: status,
        statusword: statusword,
        tsupdated: "",
        type: '',
      }
    }

    return data;
  }

  async scanner1inputchange() {

    this.switchinputs();
    this.scanner1status = '';
    this.scanner1buyername = '';
    this.scanner1tickettype = '';
    this.scanner1statusword = '';
    var scanCode = this.scanner1;

    if (this.scanner1!='') {
      this.updateLocalData(this.scanner1);

      var check = await this.checkTicketLocal(this.scanner1);
      console.log({check});
      this.scanner1data = this.getDataLocal(this.scanner1);
      this.scanner1status = this.scanner1data.status;
      this.scanner1buyername = this.scanner1data.buyername;
      this.scanner1tickettype = this.scanner1data.type;
      this.scanner1statusword = this.scanner1data.statusword;
      this.addToLogs(this.apikey, this.scanner1data);
      this.pushtoentrylogs(this.scanner1data);
      if(check) {
        this.playAlarm(this.scanner1data.status);
        this.controlService.checkticket(scanCode, this.apikey).then(data => {
          console.log("control data sent1");
        }).catch(error => {
          console.log({error});
        });
        // this.scanner1 = '';
      } else {
        this.playAlarm(false);
      }
      this.scanner1 = '';

    }
  }

  async scanner2inputchange() {
    this.switchinputs();
    this.scanner2status = '';
    this.scanner2buyername = '';
    this.scanner2tickettype = '';
    this.scanner2statusword = '';
    var scanCode = this.scanner2;

    if (this.scanner2 != '') {
      this.updateLocalData(this.scanner2);

      var check = await this.checkTicketLocal(this.scanner2);
      console.log({ check });
      this.scanner2status = this.scanner2data.status;
      this.scanner2buyername = this.scanner2data.buyername;
      this.scanner2tickettype = this.scanner2data.type;
      this.scanner2statusword = this.scanner2data.statusword;
      this.scanner2data = this.getDataLocal(this.scanner2);
      this.addToLogs(this.apikey, this.scanner2data);
      this.pushtoentrylogs(this.scanner2data);
      if (check) {
        this.playAlarm(this.scanner2data.status);
        this.controlService.checkticket(scanCode, this.apikey).then(data => {
          console.log("control data sent2");
        }).catch(error => {
          console.log({error});
        });
        // this.scanner2 = '';
      } else {
        this.playAlarm(false);
      }
      this.scanner2 = '';

    }
  }

  async scanner3inputchange() {
    this.switchinputs();
    this.scanner3status = '';
    this.scanner3buyername = '';
    this.scanner3tickettype = '';
    this.scanner3statusword = '';
    var scanCode = this.scanner3;

    if (this.scanner3 != '') {
      this.updateLocalData(this.scanner3);

      var check = await this.checkTicketLocal(this.scanner3);
      console.log({ check });
      this.scanner3data = this.getDataLocal(this.scanner3);
      this.scanner3status = this.scanner3data.status;
      this.scanner3buyername = this.scanner3data.buyername;
      this.scanner3tickettype = this.scanner3data.type;
      this.scanner3statusword = this.scanner3data.statusword;
      this.addToLogs(this.apikey, this.scanner3data);
      this.pushtoentrylogs(this.scanner3data);
      if (check) {
        this.playAlarm(this.scanner3data.status);
        this.controlService.checkticket(scanCode, this.apikey).then(data => {
          console.log("control data sent3");
        }).catch(error => {
          console.log({error});
        });
        // this.scanner3 = '';
      } else {
        this.playAlarm(false);
      }
      this.scanner3 = '';

    }
  }

  async scanner4inputchange() {
    this.switchinputs();
    this.scanner4status = '';
    this.scanner4buyername = '';
    this.scanner4tickettype = '';
    this.scanner4statusword = '';
    var scanCode = this.scanner4;

    if (this.scanner4 != '') {
      this.updateLocalData(this.scanner4);

      var check = await this.checkTicketLocal(this.scanner4);
      console.log({ check });
      this.scanner4data = this.getDataLocal(this.scanner4);
      this.scanner4status = this.scanner4data.status;
      this.scanner4buyername = this.scanner4data.buyername;
      this.scanner4tickettype = this.scanner4data.type;
      this.scanner4statusword = this.scanner4data.statusword;
      this.addToLogs(this.apikey, this.scanner4data);
      this.pushtoentrylogs(this.scanner4data);
      if (check) {
        this.playAlarm(this.scanner4data.status);
        this.controlService.checkticket(scanCode, this.apikey).then(data => {
          console.log("control data sent4");
        }).catch(error => {
          console.log({error});
        });
        // this.scanner4 = '';
      } else {
        this.playAlarm(false);
      }
      this.scanner4 = '';

    }
  }

  switchinputs() {
  	let scanner1 = <HTMLInputElement>document.getElementById("scanner1");
  	let scanner2 = <HTMLInputElement>document.getElementById("scanner2");
  	let scanner3 = <HTMLInputElement>document.getElementById("scanner3");
  	let scanner4 = <HTMLInputElement>document.getElementById("scanner4");
  	if(scanner1.value=='') {
  		this.scannerinputl.nativeElement.focus();
  	}
  	else if(scanner2.value=='') {
  		this.scannerinput2.nativeElement.focus();
  	}
  	else if(scanner3.value=='') {
  		this.scannerinput3.nativeElement.focus();
  	}
  	else if(scanner4.value=='') {
  		this.scannerinput4.nativeElement.focus();
  	}
  }

  pushtoentrylogs(topush) {
    topush.tsupdated = this.currTime();
    this.entrylogs.push(topush);
    this.entrylogsSummary.unshift(topush);
    // console.log(topush);
    if (this.entrylogsSummary.length > 10) this.entrylogsSummary = this.entrylogsSummary.slice(0, 10);
  }

  playAlarm(status) {
    if(status==false) {
      this.sound.play();
    }
  }

  apikeychange() {
    this.updateCount();
    this.getLogs();
  }

  updateCount() {
    if(this.apikey!='') {
      this.controlService.getcount(this.apikey).then(data => {
        let stats: any;
        stats = data;
        console.log("updateCount:", stats);
        this.pendingbreakdown = stats.pendingbreakdown;
        this.checkedbreakdown = stats.checkedbreakdown;
        this.totalbreakdown = stats.totalbreakdown;

        this.allLogs = stats.alllogs;

        console.log(data);
        this.totalAttendies = stats.total;
        this.totalCheckedIn = stats.checkedin;
        this.currEvent = stats.event;
      }).catch(error => {
        console.log(error);
      });


    }
  }

  currTime() {
    var currentdate = new Date();
    var tstime =currentdate.getHours() + ":" + currentdate.getMinutes();
    return tstime;
  }

  filterlog() {
    var selfRef = this;
    this.searchlogResults = this.entrylogs.filter(function(v, i) {
      if (v.buyername.toLowerCase().includes(selfRef.searchlog.toLowerCase())) {
        return true;
      } else if (v.type.toLowerCase().includes(selfRef.searchlog.toLowerCase())) {
        return true;
      } else if (v.code.includes(selfRef.searchlog)) {
        return true;
      } else if (v.tsupdated.includes(selfRef.searchlog)) {
        return true;
      } else {
        return false;
      }
    });
  }

  scrollandsearch(id, code) {
    this.searchlog = code.trim();
    this.filterlog();
    let el = document.getElementById(id);
    el.scrollIntoView();
  }

  addToLogs(apikey, scannerdata) {
    this.controlService.insertLog(apikey, scannerdata)
    .then(data => {
      console.log({data});
    })
    .catch(error => {
      console.log({error});
    });
  }

  compare(a, b) {
    if (parseInt(a.id) < parseInt(b.id)) {
      return -1;
    }
    if (parseInt(a.id) > parseInt(b.id)) {
      return 1;
    }
    return 0;
  }

  getLogs() {
    if (this.apikey != '') {
      this.controlService.getLogs(this.apikey)
      .then(async (data: any) => {
        console.log("getLogs:", data);
        if(data.length>0) {
          await data.sort(this.compare);
          for(var i=0; i<data.length; i++) {
            var toPush = {
              buyername: data[i].customer,
              type: data[i].ticket,
              code: data[i].code,
              tsupdated: data[i].scanned_time,
              status: data[i].status,
              statusword: data[i].status
            };
            this.pushexistingtoentrylogs(toPush);
          }
        }
      })
      .catch(error => {
        console.log({error});
      });
    }
  }

  pushexistingtoentrylogs(topush) {
    this.entrylogs.push(topush);
    // console.log(topush);
  }

}
