import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';
import { DataTablesModule } from 'angular-datatables';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { TimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UiModule } from './ui/ui.module';
import { FormsModule } from '@angular/forms';
import { Select2Module } from 'ng2-select2';
import { LoginComponent } from './login/login.component';
import { HomeModule } from './home/home.module';
import { ProfileComponent } from './profile/profile.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ReportComponent } from './report/report.component';
import { EventComponent } from './event/event.component';
import { SubmittedeventsComponent } from './submittedevents/submittedevents.component';
import { VieweventComponent } from './viewevent/viewevent.component';
import { CsvComponent } from './csv/csv.component';
import { ExcelService } from './excel.service';
import { TicketcontrolComponent } from './ticketcontrol/ticketcontrol.component';
// import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

// const config: SocketIoConfig = { url: 'http://localhost:3000', options: {} };

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  "bgsColor": "#DF691A",
  "bgsOpacity": 0.5,
  "bgsPosition": "bottom-right",
  "bgsSize": 60,
  "bgsType": "ball-spin-clockwise",
  "blur": 5,
  "fgsColor": "#DF691A",
  "fgsPosition": "center-center",
  "fgsSize": 60,
  "fgsType": "square-jelly-box",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 120,
  "logoUrl": "",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(205,205,205,0.8)",
  "pbColor": "#DF691A",
  "pbDirection": "ltr",
  "pbThickness": 3,
  "hasProgressBar": true,
  "text": "Loading....",
  "textColor": "#DF691A",
  "textPosition": "center-center",
  "threshold": 500
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    // HomeComponent,
    ProfileComponent,
    SidebarComponent,
    ReportComponent,
    EventComponent,
    SubmittedeventsComponent,
    VieweventComponent,
    CsvComponent,
    TicketcontrolComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    UiModule,
    FormsModule,
    DataTablesModule,
    HttpClientModule,
    HomeModule,
    Ng2GoogleChartsModule,
    DatePickerModule,
    TimePickerModule,
    Select2Module,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig)
  ],
  providers: [ExcelService],
  bootstrap: [AppComponent]
})
export class AppModule { }
