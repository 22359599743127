import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CsvService {

  baseUrl: any;
  secureTok: any;
  ordersLimit: any;
  httpOptions: any;

  constructor(private http: HttpClient, private router: Router) {
    this.baseUrl = 'https://otayo.com/producersapi/reportsapi/';
    this.secureTok = '?tok=ruer75874lkrlkjejdhrw3463434';
    this.ordersLimit = 50;
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
    };
  }

  async getAllEvents() {
    return await this.http.get(this.baseUrl + 'getallevents.php' + this.secureTok).toPromise();
  }

  async getExported(filters, count) {
    // let totalPage = count/this.ordersLimit;
    // var data = [];
    // var i =1;
    // for (var i = 0; i <= totalPage; i++) {
    return await this.http.post(this.baseUrl + 'getorders.php' + this.secureTok, JSON.stringify(filters), this.httpOptions)
      .toPromise();
    // .then(orders => {
    //   // data.push(orders);
    //   data = orders;
    // });
    // }
    // console.log(data);
    // return forkJoin(data).toPromise();
  }

  async getExportedOld(filters, count) {
    // let totalPage = count/this.ordersLimit;
    // var data = [];
    // var i =1;
    // for (var i = 0; i <= totalPage; i++) {
    return await this.http.post('https://otayo.com/tickets/producersapi/reportsapi/' + 'getorders.php' + this.secureTok, JSON.stringify(filters), this.httpOptions)
      .toPromise();
    // .then(orders => {
    //   // data.push(orders);
    //   data = orders;
    // });
    // }
    // console.log(data);
    // return forkJoin(data).toPromise();
  }

  async getOldEvent(id) {
    return await this.http.get('https://otayo.com/producersapi/reportsapi/getoldeventid.php?tok=ruer75874lkrlkjejdhrw3463434&id=' + id).toPromise();
  }

  async getCount(filters) {
    return await this.http.post(this.baseUrl + 'getordercount.php' + this.secureTok, JSON.stringify(filters), this.httpOptions).toPromise();
  }
}
